export const CARD_SECTION_MARKERS = {
  ACTION_BTN: 'Action Button',
  REVIEWS: 'Reviews',
  DESCRIPTORS: 'Descriptors',
  CATEGORY: 'Category',
  IMAGE: 'Image',
  WHITESPACE: 'Whitespace',
  TITLE: 'Title',
  PRICING: 'Pricing',
  AVAILABILITY: 'Availability',
};

export const CLOSE_DRAWER_ACTIONS = {
  OVERLAY_CLICKED: 'Overlay Clicked',
  SWIPE_DOWN: 'Swipe Down',
};

export const SWIPESHEET_STATES = {
  HIDDEN: 'hidden',
  OPEN: 'open',
  EXPANDED: 'expanded',
};
