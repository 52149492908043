/*
  The central source of all typography styles.
  This should always match the Figma File "🟢 Typesetting 2.0"
  Figma URL - https://www.figma.com/file/dx4VAZwqg1GK3N46oh10dg/%F0%9F%9F%A2-Typesetting-2.0?node-id=3912%3A786
  Last Updated - "Tue May 17 2022 11:49:37 GMT+0530 (India Standard Time)"
*/

const TYPOGRAPHY: Record<string, Record<string, string | number>> = {
  'Display/Large': {
    fontFamily: 'halyard-display, sans-serif',
    fontSize: 48,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 54,
    lineHeightPercent: 113,
    letterSpacing: 0.6,
  },
  'Display/Regular': {
    fontFamily: 'halyard-display, sans-serif',
    fontSize: 36,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 44,
    lineHeightPercent: 122,
    letterSpacing: 0.6,
  },
  'Display/Small': {
    fontFamily: 'halyard-display, sans-serif',
    fontSize: 30,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 38,
    lineHeightPercent: 127,
    letterSpacing: 0.4,
  },
  'Heading/Large': {
    fontFamily: 'halyard-display, sans-serif',
    fontSize: 24,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 28,
    lineHeightPercent: 117,
    letterSpacing: 0.8,
  },
  'Heading/Regular': {
    fontFamily: 'halyard-display, sans-serif',
    fontSize: 21,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 28,
    lineHeightPercent: 133,
    letterSpacing: 0.8,
  },
  'Heading/Small': {
    fontFamily: 'halyard-display, sans-serif',
    fontSize: 18,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 24,
    lineHeightPercent: 133,
    letterSpacing: 0.6,
  },
  'Heading/XS': {
    fontFamily: 'halyard-display, sans-serif',
    fontSize: 15,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 20,
    lineHeightPercent: 133,
    letterSpacing: 0.6,
  },
  'Heading/Product Card': {
    fontFamily: 'halyard-display, sans-serif',
    fontSize: 17,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 20,
    lineHeightPercent: 118,
    letterSpacing: 0.6,
  },
  'Subheading/Large': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 20,
    lineHeightPercent: 125,
    letterSpacing: 0,
  },
  'Subheading/Regular': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 20,
    lineHeightPercent: 143,
    letterSpacing: 0,
  },
  'Subheading/Small': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 12,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 16,
    lineHeightPercent: 133,
    letterSpacing: 0,
  },
  'Subheading/XS': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 12,
    fontWeight: 400,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 16,
    lineHeightPercent: 133,
    letterSpacing: 0,
  },
  'Button/Big': {
    fontFamily: 'halyard-display, sans-serif',
    fontSize: 20,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 24,
    lineHeightPercent: 120,
    letterSpacing: 0.8,
  },
  'Button/Medium': {
    fontFamily: 'halyard-display, sans-serif',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 20,
    lineHeightPercent: 125,
    letterSpacing: 0.6,
  },
  'Button/Small': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 16,
    lineHeightPercent: 114,
    letterSpacing: 0.2,
  },
  'UI/Label Large': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 17,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: "'SS04' 1",
    lineHeight: 20,
    lineHeightPercent: 118,
    letterSpacing: 0,
  },
  'UI/Label Medium': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 15,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: "'SS04' 1",
    lineHeight: 20,
    lineHeightPercent: 133,
    letterSpacing: 0,
  },
  'UI/Label Regular': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 14,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 16,
    lineHeightPercent: 114,
    letterSpacing: 0,
  },
  'UI/Label Small': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 12,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 16,
    lineHeightPercent: 133,
    letterSpacing: 0,
  },
  'UI/Label XS': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 10,
    fontWeight: 400,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 12,
    lineHeightPercent: 120,
    letterSpacing: 0,
  },
  'UI/Label Caption': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 11,
    fontWeight: 300,
    fontStyle: 'italic',
    fontFeatureSettings: 'normal',
    lineHeight: 12,
    lineHeightPercent: 109,
    letterSpacing: 0,
  },
  'UI/Label Large (Heavy)': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 17,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: "'SS04' 1",
    lineHeight: 20,
    lineHeightPercent: 118,
    letterSpacing: 0,
  },
  'UI/Label Medium (Heavy)': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 15,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: "'SS04' 1",
    lineHeight: 20,
    lineHeightPercent: 133,
    letterSpacing: 0,
  },
  'UI/Label Regular (Heavy)': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 16,
    lineHeightPercent: 114,
    letterSpacing: 0,
  },
  'UI/Label Small (Heavy)': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 12,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 16,
    lineHeightPercent: 133,
    letterSpacing: 0,
  },
  'Paragraph/Large': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 17,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: "'SS04' 1",
    lineHeight: 28,
    lineHeightPercent: 165,
    letterSpacing: 0,
  },
  'Paragraph/Medium': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 15,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: "'SS04' 1",
    lineHeight: 24,
    lineHeightPercent: 160,
    letterSpacing: 0,
  },
  'Paragraph/Regular': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 14,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 20,
    lineHeightPercent: 143,
    letterSpacing: 0,
  },
  'Paragraph/Small': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 12,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 20,
    lineHeightPercent: 167,
    letterSpacing: 0,
  },
  'Paragraph/XS': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 10,
    fontWeight: 400,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 16,
    lineHeightPercent: 160,
    letterSpacing: 0,
  },
  'Paragraph/Caption': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 11,
    fontWeight: 300,
    fontStyle: 'italic',
    fontFeatureSettings: 'normal',
    lineHeight: 16,
    lineHeightPercent: 145,
    letterSpacing: 0,
  },
  'Paragraph/Blockquote': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 14,
    fontWeight: 300,
    fontStyle: 'italic',
    fontFeatureSettings: "'SS04' 1",
    lineHeight: 20,
    lineHeightPercent: 143,
    letterSpacing: 0,
  },
  'List/Small': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 12,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 20,
    lineHeightPercent: 167,
    letterSpacing: 0,
  },
  'List/Regular': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 14,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 20,
    lineHeightPercent: 143,
    letterSpacing: 0,
  },
  'List/Medium': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 15,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: "'SS04' 1",
    lineHeight: 24,
    lineHeightPercent: 160,
    letterSpacing: 0,
  },
  'List/Large': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 17,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: "'SS04' 1",
    lineHeight: 28,
    lineHeightPercent: 165,
    letterSpacing: 0,
  },
  'Misc/Overline Large': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 12,
    fontWeight: 400,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 16,
    lineHeightPercent: 133,
    letterSpacing: 0.6,
  },
  'Misc/Overline': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 10,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 12,
    lineHeightPercent: 120,
    letterSpacing: 0.8,
  },
  'Misc/Tag Heavy': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 13,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 12,
    lineHeightPercent: 92,
    letterSpacing: 0.4,
  },
  'Misc/Tag Medium': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 13,
    fontWeight: 400,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 12,
    lineHeightPercent: 92,
    letterSpacing: 0.1,
  },
  'Misc/Tag Regular': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 11,
    fontWeight: 400,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 12,
    lineHeightPercent: 109,
    letterSpacing: 0.2,
  },
  'Misc/Badge Small': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 10,
    fontWeight: 400,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 12,
    lineHeightPercent: 120,
    letterSpacing: 0.2,
  },
  'Misc/Booster': {
    fontFamily: 'halyard-display, sans-serif',
    fontSize: 10,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: 'normal',
    lineHeight: 12,
    lineHeightPercent: 120,
    letterSpacing: 1,
  },
  'Table/Medium (Heavy)': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 15,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: "'SS04' 1 'LNUM' 1 'TNUM' 1",
    lineHeight: 20,
    lineHeightPercent: 133,
    letterSpacing: 0,
  },
  'Table/Medium': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 15,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: "'SS04' 1 'LNUM' 1 'TNUM' 1",
    lineHeight: 20,
    lineHeightPercent: 133,
    letterSpacing: 0,
  },
  'Table/Regular (Heavy)': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: "'LNUM' 1 'TNUM' 1",
    lineHeight: 16,
    lineHeightPercent: 114,
    letterSpacing: 0,
  },
  'Table/Regular': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 14,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: "'LNUM' 1 'TNUM' 1",
    lineHeight: 16,
    lineHeightPercent: 114,
    letterSpacing: 0,
  },
  'Table/Large (Heavy)': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 17,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: "'SS04' 1",
    lineHeight: 20,
    lineHeightPercent: 118,
    letterSpacing: 0,
  },
  'Table/Large': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 17,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: "'SS04' 1 'LNUM' 1 'TNUM' 1",
    lineHeight: 20,
    lineHeightPercent: 118,
    letterSpacing: 0,
  },
  'Table/Small (Heavy)': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 12,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFeatureSettings: "'LNUM' 1 'TNUM' 1",
    lineHeight: 16,
    lineHeightPercent: 133,
    letterSpacing: 0,
  },
  'Table/Small': {
    fontFamily: 'halyard-text, sans-serif',
    fontSize: 12,
    fontWeight: 300,
    fontStyle: 'normal',
    fontFeatureSettings: "'LNUM' 1 'TNUM' 1",
    lineHeight: 16,
    lineHeightPercent: 133,
    letterSpacing: 0,
  },
};

Object.freeze(TYPOGRAPHY);

export function expandFontToken(label: string = 'PARAGRAPH_LARGE') {
  const {
    fontFamily,
    fontSize,
    fontWeight,
    fontStyle,
    fontFeatureSettings,
    lineHeight,
    letterSpacing,
  } = TYPOGRAPHY[label];

  const style = `
    font-family: ${fontFamily};
    font-size: ${fontSize}px;
    font-weight: ${fontWeight};
    font-style: ${fontStyle};
    font-feature-settings: ${fontFeatureSettings};
    line-height: ${lineHeight}px;
    letter-spacing: ${letterSpacing}px;
  `;

  return style;
}
