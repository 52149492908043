const InfoIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="10" fill="white" fillOpacity="0.88" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5.25C9.58579 5.25 9.25 5.58579 9.25 6C9.25 6.41421 9.58579 6.75 10 6.75H10.01C10.4242 6.75 10.76 6.41421 10.76 6C10.76 5.58579 10.4242 5.25 10.01 5.25H10ZM10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10V14C9.25 14.4142 9.58579 14.75 10 14.75C10.4142 14.75 10.75 14.4142 10.75 14V10Z"
      fill="black"
    />
  </svg>
);
export default InfoIcon;
